.small-screen-warning {
  display: none;
}

.hide {
  display: none !important;
}

.hide-opacity {
  opacity: 0 !important;
  min-width: 185px;
}

.font-bold {
  font-weight: bold;
}

.App {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.App__version {
  position: absolute;
  top: 20px;
  left: 20px;
  color: rgba(255, 255, 255, 0.5);
  z-index: 999;
}

.App--print {
  background: #fff;
}

.App__content {
  position: absolute;
  background: #fff;
  width: 100vw;
  max-width: 1100px;
  /* min-width: 1000px; */
  height: 100vh;
  /* min-height: 750px; */
  max-height: 750px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media all and (min-height: 750px) {
  .App__content {
    top: 0;
  }
}

@media all and (min-height: 800px) {
  .App__content {
    top: 2vh
  }
}

@media all and (min-height: 850px) {
  .App__content {
    top: 4vh;
  }
}

@media all and (min-height: 1000px) {
  .App__content {
    top: 8vh
  }
}

@media all and (min-height: 1100px) {
  .App__content {
    top: 12vh
  }
}


.App__content--print {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.App__content--left, .App__content--left--intro {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.App__content--left{
  margin-left: 10px;
}

.App__content--left--intro {
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 13px;
}

.App__content--left-print {
  display: none;
}

.App__content--center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.App__content--right {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}

.App__content--right-print {
  display: none;
}

.App__workspace {
  border: 1px solid black;
  box-shadow: 5px 10px 10px 5px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
}

.App--print .App__workspace {
  box-shadow: none;
  pointer-events: none;
}

@media only screen and (max-width: 999px), only screen and (max-height: 749px) {
  .App__content {
      display: none;
  }

  .small-screen-warning {
      display: block;
      color: #000;
      padding: 25px;
      text-align: center;
  }
}

@media (max-width: 1150px) {
  body {
      transform: scale(0.95);
  }
}

@media (max-width: 1100px) {
  body {
      transform: scale(0.90);
  }
}
