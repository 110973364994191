.color-button-palette__wrapper {
    margin-top: 40px;
    -webkit-box-shadow: 5px 10px 5px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 5px 10px 5px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 10px 5px 3px rgba(0, 0, 0, 0.3);
}

.color-button-palette__image {
    width: 52px;
    margin-bottom: -4px;
}
.color-button-palette__button {
    display: block;
    width: 50px;
    height: 26px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    -webkit-transition: -webkit-transform 100ms;
    transition: transform 100ms;
    cursor: pointer;
}

.color-button-palette__button:first-child {
    border-top: 1px solid #000;
}

.color-button-palette__button:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    border-top: 1px solid #000;
}

.color-button-palette__button:active {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}