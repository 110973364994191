.select-shape-button {
    -webkit-box-shadow: 3px 4px 3px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 3px 4px 3px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 3px 4px 3px 2px rgba(0, 0, 0, 0.3);
    border: 1px solid #000;
    padding: 3px;
    width: 40px !important;
    height: 40px !important;
}

.select-shape-button--active {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    border: 3px solid #000;
    margin: 0 9px !important;
}

.select-shape-button img {
    width: 40px !important;
    height: 40px !important;
}