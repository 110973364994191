.svg-container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

.svg-container {
    position: relative;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
}

.svg-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 42px;
    height: 42px;
}