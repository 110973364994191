.qr__center {
    width: 100vw;
    height: 100vh;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.qr__back-button {
    margin-top: 40px;
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #000000;
    border-radius: 4px;
    text-decoration: none;
    color: #000000;
    transition: all 120ms;
}

.qr__back-button:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    background-color: rgba(0, 0, 0, 0.15);
}