.custom-color-input {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    padding: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
    background: rgba(0,0,0,0);
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 30px;
    padding-top: 27px;
}

.custom-color-input__image {
    max-width: 48px;
    max-height: 48px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    -webkit-animation-name: spin;
    animation-name: spin;
    -webkit-animation-duration: 5000ms;
    animation-duration: 5000ms;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.custom-color-input__wrapper {
    max-width: 52px;
    height: 51px;
    position: relative;
    padding: 2px;
    margin: 0;
    border: 1px solid #000;
    border-top: none;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-transition: all 120ms;
    transition: all 120ms;
}

.custom-color-input__wrapper:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

@-webkit-keyframes spin { 
    from { 
        -webkit-transform: rotate(0deg); 
        transform: rotate(0deg); 
    } to { 
        -webkit-transform: rotate(360deg); 
        transform: rotate(360deg); 
    }
}

@keyframes spin { 
    from { 
        -webkit-transform: rotate(0deg); 
        transform: rotate(0deg); 
    } to { 
        -webkit-transform: rotate(360deg); 
        transform: rotate(360deg); 
    }
}