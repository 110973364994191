.header {
    color: #000;
    width: 100%;
    text-align: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}

.header--print {
    display: block;
}

.header__text {
    font-size: 15px;
}

.header__timer {
    font-size: 16px;
    font-weight: 700;
    position: absolute;
    /* right: 0; */
    top: 0;
    color: #D1D3D4;
    white-space: nowrap;
}

.header__timer--hide {
    display: none;
}

@media all and (max-width: 1070px) {
    .header__timer {
        right: -10px;
    }
}

@media all and (max-width: 1030px) {
    .header__timer {
        right: -25px;
    }
}
