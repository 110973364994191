/* LanguageSwitcher.css */

.language-switcher {
  padding: 6px;
  margin: 10px 0;
  border: 1px solid #808285;
  border-radius: 4px;
  background-color: #F2F3F4;
  color: #333;
  outline: none;
  cursor: pointer;
  width: 60px !important;
  -webkit-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
}

.language-switcher:active, .language-switcher:hover {
  border-color: #666;
}

.language-option {
  /* Customize the styles for the option elements if needed */
  /* For example, you can change font-size, padding, background-color, etc. */
  /* Sample styles below */
  padding: 4px;
  background-color: #f2f2f2;
  color: #333;
}

.language-option:selected {
  /* Add custom styles for the selected option */
  /* For example, you can change the font-weight or background-color to indicate the selected option */
  font-weight: bold;
}
