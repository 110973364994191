.share-pop-up {
    display: none;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background: #fff;
    color: #000;
    border: 3px solid #000;
    z-index: 96;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -60%);
    transform: translate(-50%, -60%);
    border-radius: 10px;
    padding: 20px 40px;
    min-width: 390px;
    max-width: 500px;
    height: auto;
    -webkit-box-shadow: 5px 10px 5px 3px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 5px 10px 5px 3px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 10px 5px 3px rgba(0, 0, 0, 0.5);
}

.share-pop-up--not-modal {
    border: none;
    position: relative;
    top: auto;
    left: auto;
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
    margin-top: 25px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.share-pop-up--show {
    display: -webkit-flex;
    display: flex;
}

.share-pop-up__share-screen {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 32px;
}

.share-pop-up__share-screen--show {
    display: -webkit-flex;
    display: flex;
}

.share-pop-up__share-screen--hide {
    display: none;
}

.share-pop-up__input {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
    padding: 4px 0;
}

.share-pop-up__buttons-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    width: 100%;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
}

.share-pop-up__button {
    margin-top: 15px;
    padding: 5px 10px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
    transition: all 120ms;
}

.share-pop-up__button--top {
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 15px;
}

.share-pop-up__button--main {
    background-color: rgba(255, 166, 0, 0.5);
    font-weight: 600;
}

.share-pop-up__button--hide {
    display: none;
}

.share-pop-up__button:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    background-color: rgb(225, 225, 225);
}

.share-pop-up__button--main:hover {
    background-color: rgba(255, 166, 0, 0.8);
}

.share-pop-up__button:active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.share-pop-up__action-status {
    color: red;
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 15px;
    min-height: 17px;
    width: 100%;
    text-align: center;
}

.share-pop-up__share-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
}

.share-pop-up__share-row--hide {
    display: none;
}

.share-pop-up__share-label {
    margin-right: 10px;
}

.share-pop-up__share-input-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.share-pop-up__share-input-wrapper > span {
    min-width: 27px;
}

.share-pop-up__share-input {
    margin-right: 10px;
    width: auto;
    text-align: center;
    padding: 4px 0px;
}

.share-pop-up__social-share-wrapper {
    display: none;
    margin-bottom: 20px;
    /* display: -webkit-flex;
    display: flex; */
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    width: 100%;
}

.share-pop-up__input.share-pop-up__input--text {
    display: none;
}
