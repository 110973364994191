.settings-pop-up {
    display: none;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background: #fff;
    color: #000;
    z-index: 96;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -52%);
    transform: translate(-50%, -52%);
    border-radius: 10px;
    padding: 20px 40px;
    min-width: 550px;
    max-width: 1100px;
    width: auto;
    height: auto;
    box-sizing: border-box;
    transition: all 120ms;
    border: 1px solid #808285;
}

.settings-pop-up--show {
    display: -webkit-flex;
    display: flex;
}

.settings-pop-up__login-screen {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 32px;
}

.settings-pop-up__login-screen--show {
    display: -webkit-flex;
    display: flex;
}

.settings-pop-up__login-screen--hide {
    display: none;
}

.settings-pop-up__settings-screen {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding-top: 32px;
}

.settings-pop-up__settings-screen-content-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
}

.settings-pop-up__settings-screen--half {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 47%;
    height: auto;
}

.settings-pop-up__settings-screen--half--left {
    margin-right: 50px;
}

.settings-pop-up__settings-screen--half > img {
    margin: 15px auto;
    display: block;
}

.settings-pop-up__settings-screen--show {
    display: -webkit-flex;
    display: flex;
}

.settings-pop-up__settings-screen--hide {
    display: none;
}

.settings-pop-up__input {
    margin-bottom: 10px;
    text-align: center;
    width: 70%;
    padding: 4px 0;
}

.settings-pop-up__buttons-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    width: 60%;
    -webkit-justify-content: center;
    justify-content: center;
}

.settings-pop-up__button {
    margin: 15px 10px 0 10px;
    padding: 5px 25px;
    background-color: #F2F3F4;
    cursor: pointer;
    border-radius: 5px;
    transition: all 120ms;
    border: 1px solid #808285;
}

.settings-pop-up__button--top {
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 15px;
}

.settings-pop-up__button--reset {
    background-color: #EE3E35;
    color: #fff; 
}

.settings-pop-up__button--main {
    background-color: #1074BC;
    color: #fff;
}

.settings-pop-up__button--hide {
    display: none;
}

.settings-pop-up__button:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    background-color: rgb(225, 225, 225);
}

.settings-pop-up__button--main:hover {
    background-color: #00538f;
}

.settings-pop-up__button--reset:hover {
    background-color: #df1b10;
}

.settings-pop-up__button:active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.settings-pop-up__action-status {
    color: red;
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 15px;
    min-height: 17px;
    width: 100%;
    text-align: center;
}

.settings-pop-up__settings-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
}

.settings-pop-up__settings-row--hide {
    display: none;
}

.settings-pop-up__settings-label {
    margin-right: 25px;
    white-space: nowrap;
}

.settings-pop-up__settings-input-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.settings-pop-up__settings-input-wrapper input[type="file"] {
    color: #808285;
}

.settings-pop-up__settings-input-wrapper input[type="file"]::file-selector-button {
    padding: 5px;
    background-color: #F2F3F4;
    cursor: pointer;
    border-radius: 5px;
    transition: all 120ms;
    border: 1px solid #808285;
    margin-right: 10px;
  }

.settings-pop-up__settings-input-wrapper > span {
    min-width: 27px;
}

.settings-pop-up__settings-input {
    width: auto;
    text-align: center;
    padding: 4px 0px;
    width: 60px !important;
    background-color: #F2F3F4;
    border: 1px solid #808285;
    border-radius: 5px;
  }

.settings-pop-up__settings-input-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.settings-pop-up__horizontal-line {
    display: block;
    width: 100%;
    height: 0px;
    margin: 15px 0;
}