.more-time-pop-up {
    display: none;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background: #fff;
    color: #000;
    border: 3px solid #000;
    z-index: 96;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -60%);
    transform: translate(-50%, -60%);
    border-radius: 10px;
    padding: 20px 40px;
    min-width: 390px;
    max-width: 500px;
    -webkit-box-shadow: 5px 10px 5px 3px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 5px 10px 5px 3px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 10px 5px 3px rgba(0, 0, 0, 0.5);
}

.more-time-pop-up--show {
    display: -webkit-flex;
    display: flex;
}

.more-time-pop-up__text {
    text-align: center;
    white-space: pre-line;
    font-weight: 600;
}

.more-time-pop-up__button {
    margin-top: 15px;
    padding: 5px 30px;
    cursor: pointer;
}