.footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.footer-logo--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 55px;
}

.footer-logo {
    margin: 15px 0;
}

.footer-logo--artwork {
    margin: 30px 0 15px 0;
}

.footer--print {
    display: none;
}

.footer__icon-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.footer__icon-wrapper div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer__icon {
    width: auto;
    height: auto;
    max-width: 48px;
    margin: 0 10px;
    cursor: pointer;
    -webkit-transition: -webkit-transform 100ms;
    transition: transform 100ms;
}

.footer__icon.footer__icon--back-to-intro {
    margin-right: 0;
}

.footer__icon--rotating-icon-container {
    margin-right: 10px;
}

.footer__icon:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.footer__icon:active {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.footer__icon--settings {
    margin-top: 68px;
    font-size: 50px;
}

.footer__icon--scale-08,
.footer__icon--scale-07,
.footer__icon--scale-06 {
    -webkit-transform-origin: center;
    transform-origin: center;
}

.footer__icon--scale-08 {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
}

.footer__icon--scale-07 {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
}

.footer__icon--scale-06 {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
}

.footer__icon--scale-08:hover {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.footer__icon--scale-07:hover {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
}

.footer__icon--scale-06:hover {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
}

.footer__icon--scale-08:active {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
}

.footer__icon--scale-07:active {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
}

.footer__icon--scale-06:active {
    -webkit-transform: scale(0.65);
    transform: scale(0.65);
}

.footer__temporary-button {
    height: 40px;
    width: auto;
    max-width: none;
    font-size: 11px;
    margin-left: 5px;
    margin-right: 0;
}