@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

.intro__animation-wrapper {
    position: relative;
    display: block;
    border: 1px solid black;
    width: 755px;
    height: 560px;
    -webkit-box-shadow: 5px 10px 10px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 5px 10px 10px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 10px 10px 5px rgba(0, 0, 0, 0.3);
    pointer-events: none;
}

.intro__animation--artist-name {
    position: absolute;
    top: 40px;
    left: 20px;
    font-size: 72px;
    letter-spacing: 1px;
    color: #F77703;
    text-shadow: 4px 3px 0px rgb(0, 0, 0);
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
}

.intro__animation--artwork-name {
    position: absolute;
    top: 120px;
    left: 20px;
    font-size: 64px;
    letter-spacing: 0.5px;
    color: #FFF500;
    text-shadow: 5px 3px 0px rgba(66, 68, 90, 1);
    margin: 0;
    padding: 0;
    line-height: 1.1;
    font-family: 'Montserrat', sans-serif;
}

.intro__animation--tag-line-main {
    position: absolute;
    top: 420px;
    left: 20px;
    font-size: 35px;
    color: #FBFBFB;
    text-shadow: 3px 3px 0px rgba(66, 68, 90, 1);
    margin: 0;
    padding: 0;
    line-height: 1.15;
    font-family: 'Montserrat', sans-serif;
}

.intro__footer {
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 30px;
    width: 100%;
    height: auto;
}

.intro__button {
    text-transform: uppercase;
    padding: 5px;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    transition: all 120ms;
    background-color: transparent;
    border: 0;
    color: #5B5B5B;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
}

.intro__button:hover, .intro__button:active {
    color: #0800CD;
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
}