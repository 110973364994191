.demo-instructions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    position: fixed;
    z-index: 90;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -375px);
    transform: translate(-50%, -375px);
    width: 1000px;
    height: 0px;
}

.demo-instructions--hide {
    display: none;
}

.demo-instructions__grid-element {
    background: rgba(0, 0, 0, 0.5);
    width: 25px;
    height: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    color: #fff;
    font-size: 10px;
    box-sizing: border-box;
}

.demo-instructions__grid-element--hide {
    opacity: 0;
    pointer-events: none;
}

.demo-instructions__hint-wrapper {
    position: fixed;
    z-index: 95;
    top: 50%;
    left: 50%;
    width: 430px;
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    background: rgba(255, 255, 255, 1);
    border: 2px solid #000;
    border-radius: 10px;
    padding: 40px 30px;
    box-sizing: border-box;
}

.demo-instructions__hint-wrapper::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 87px;
    right: auto;
    top: auto;
    bottom: -26px;
    border: 12px solid;
    border-color: rgb(0, 0, 0) transparent transparent rgb(0, 0, 0);
}

.demo-instructions__hint-wrapper::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 90px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: lightyellow transparent transparent lightyellow;
}

.demo-instructions__hint-title {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 10px 0;
}

.demo-instructions__hint-text {
    text-align: center;
    margin: 0;
}

.demo-instructions__hint-button {
    padding: 5px 20px;
    margin: 15px 0 0;
    cursor: pointer;
}