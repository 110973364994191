.stripe-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-right: 15px;
    margin-left: 10px;
}

.stripe-button {
    display: block;
    height: 45px;
    width: 12px;
    background: transparent;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    cursor: pointer;
    -webkit-transition: -webkit-transform 100ms;
    transition: transform 100ms;
    margin-right: 7px;
    -webkit-box-shadow: 3px 4px 3px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 3px 4px 3px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 3px 4px 3px 2px rgba(0, 0, 0, 0.3);
}

.stripe-button:last-child {
    margin-right: 0;
}

.stripe-button:hover {
    border-right: 1px solid gray;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.stripe-button:active {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.stripe-button--active {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    border: 2px solid #000 !important;
}

/* Vendor-prefixed properties */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .stripe-button:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .stripe-button:active,
    .stripe-button--active {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
    }
}